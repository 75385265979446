import { age } from '../utils/index'

export const seo = {
  url: 'poznaj-nas',
  title: {
    pl: `Poznaj Omida Trade - dystrybutora kontenerów morskich.`,
    en: `Discover one of the best forwarding in Poland. ${age}-years of experience in the industry`,
  },
  desc: {
    pl: 'Poznaj Omida Trade - dystrybutora kontenerów morskich.',
    en: 'The best forwarding in Poland, international transport, TSL company with many years of experience. Discover the history of Omida Logistics',
  },
  keywords: ['omida', 'logistics', 'transport'],
}

export const intro = {
  title: {
    pl: 'Poznaj Nas',
    en: 'Discover Us',
  },
  desc: {
    pl: `Poznaj Omida Trade - dystrybutora kontenerów morskich.`,
    en: `Welcome to Omida Logistics - the most recognizable Polish brand of TSL.`,
  },
  button: {
    text: {
      pl: 'Czytaj więcej',
      en: 'Read more',
    },
    action: 'SCROLL',
  },
}

export const main = {
  body: [
    {
      headline: {
        pl: 'Stawiamy na szybką obsługę Klientów w Polsce i Europie',
        en: 'The best forwarding in Poland, international transport, TSL company with many years of experience. Learn the history of Omida Logistics.',
      },
      texts: [
        {
          pl: `Stworzyliśmy Omida Trade - widzimy ogromny potencjał wykorzystania kontenerów w branży transportowej, automotive oraz innych branżach.`,
          en: `We are a Polish company who is Road Transport Leader in both Poland and Europe. Omida Logistics is a brand of the OMIDA group, which was established in 2010. Our structure consists of 20 branches located all over Poland, which is represented by over 200 forwarders, and a network of several hundred carriers in Poland and Europe.`,
        },
        {
          pl: `Nazwa Omida Trade to połączenie dwóch członów - Omida jako Grupa podmiotów zajmujących się transportem oraz Trade - jako handel, czyli sprzedaż czegoś większego. Obecnie skupiamy się na sprzedaży głównie w Europie oraz Azji.`,
          en: `Throughout these ${age} years, we have been continuously implementing the strategy of dynamic development. The founders of Omida Logistics are Bartłomiej Glinka and Marek Rostkowski. The headquarters of Omida Logistics is located in the Olivia Business Centre in the Olivia Four building on the 9th floor.`,
        },
      ],
    },
    {
      headline: {
        pl: 'Usługi komplementarne do sprzedaży kontenerów',
        en: 'History of the Polish transport company - Omida Logistics',
      },
      texts: [
        {
          pl: 'Mamy całe zaplecze logistyczne, gotowe do zastosowania w obsłudze Klienta biznesowego, który potrzebuje bardzo szybko zamówić kontener oraz dostarczyć go pod wskazany adres.',
          en: '<span>Our story began in <strong>Gdańsk</strong>. The founders of our company, <strong>Bartłomiej Glinka</strong> and <strong>Marek Rostkowski</strong>, open Omida Logistics and create a branch of several people in the office with a view of Westerplatte. Within 3 years we created several branches in <strong>Warsaw</strong>, <strong>Poznań</strong>, <strong>Katowice</strong> and <strong>Sopot</strong>. Guided by the principle that Omida is people, after 5 years of operation in our company, we employed a <strong>team of over 100 specialists</strong>. In 2015, we moved our headquarters to the most modern business center in Pomerania - <strong>Olivia Business Center in Gdańsk</strong>.</span>',
        },
      ],
    },
    {
      headline: {
        pl: 'Ponad 1,4 miliard złotych przychodu w 2022 roku i nowe oddziały międzynarodowe',
        en: 'History of the Polish transport company - Omida Logistics',
      },
      texts: [
        {
          pl: 'Jesteśmy częścią Omida Group - Integratora logistycznego o globalnym zasięgu. Omida Trade uzupełnia ofertę całej grupy o ważny aspekt jakim jest dostępność kontenerów morskich o różnej specyfice wykorzystywanych w transporcie wszelkiego rodzaju ładunków.',
          en: '<span>Our story began in <strong>Gdańsk</strong>. The founders of our company, <strong>Bartłomiej Glinka</strong> and <strong>Marek Rostkowski</strong>, open Omida Logistics and create a branch of several people in the office with a view of Westerplatte. Within 3 years we created several branches in <strong>Warsaw</strong>, <strong>Poznań</strong>, <strong>Katowice</strong> and <strong>Sopot</strong>. Guided by the principle that Omida is people, after 5 years of operation in our company, we employed a <strong>team of over 100 specialists</strong>. In 2015, we moved our headquarters to the most modern business center in Pomerania - <strong>Olivia Business Center in Gdańsk</strong>.</span>',
        },
        {
          pl: '<span>W kolejnych latach naszej działalności planujemy kontynuowanie <strong>strategii dynamicznego rozwoju</strong> i mamy zamiar otworzyć nasze oddziały w kluczowych lokalizacjach w <strong>Polsce i Europie.</strong></span>',
          en: '<span>Our story began in <strong>Gdańsk</strong>. The founders of our company, <strong>Bartłomiej Glinka</strong> and <strong>Marek Rostkowski</strong>, open Omida Logistics and create a branch of several people in the office with a view of Westerplatte. Within 3 years we created several branches in <strong>Warsaw</strong>, <strong>Poznań</strong>, <strong>Katowice</strong> and <strong>Sopot</strong>. Guided by the principle that Omida is people, after 5 years of operation in our company, we employed a <strong>team of over 100 specialists</strong>. In 2015, we moved our headquarters to the most modern business center in Pomerania - <strong>Olivia Business Center in Gdańsk</strong>.</span>',
        },
      ],
    },
  ],
}

export const video = {
  title: {
    pl: '',
    en: '',
  },
  desc: {
    pl: '',
    en: '',
    // pl: '(Październik 2020) Wiele godzin planowania, realizacji i montażu. Wszystko po to, aby podsumować dotychczasowe 10 lat w branży TSL. O tym jak powstała #OmidaGroup i jakie decyzje trzeba było podjąć, aby stać się jedną z najbardziej rozpoznawalnych marek w branży TSL w Polsce, opowiedzieli Marek Rostkowski i Bartłomiej Glinka, Prezesi Omida To wyjątkowy czas dla naszej firmy, zachęcamy do komentowania i udostępniania - pokażmy wspólnymi siłami jak silna, jest marka Omida Group.',
    // en: "(October 2020) Many hours of planning, recording and video editing. All this to summarize the 10 years in the TSL industry to date. Marek Rostkowski and Bartłomiej Glinka, Presidents of Omida, told about how #OmidaGroup was created and what decisions had to be made to become one of the most recognizable brands in the TSL industry in Poland. This is a special time for our company, we encourage you to comment and share - let's show together how strong the Omida Group brand is.",
  },
  url: 'https://www.youtube.com/embed/P3S5QVpjWmI',
}
