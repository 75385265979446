import React from 'react'
import ReactPlayer from 'react-player/lazy'
import { useLangContext } from '../context/lang.context'
import { colors, s } from '../style'
import Headline from './Headline'
import Section from './Section'

const Video = ({ data }) => {
  const { lang } = useLangContext()
  const { title, desc, url, more } = data
  return (
    <>
      <Section top bottomXS>
        <Headline
          h={2}
          title={title?.[lang]}
          desc={desc?.[lang]}
          large
          gradient
        />
      </Section>
      <Section topXS mobile bottom>
        <>
          <div css={sPlayer}>
            <ReactPlayer
              autoPlay
              className='react-player'
              url={url}
              loop
              width='100%'
              height='100%'
              playsinline
              config={{
                youtube: {
                  playerVars: {
                    controls: 1,
                    showinfo: 0,
                    modestbranding: 1,
                    rel: 0,
                    frameborder: 0,
                    // disablekb: 1,
                  },
                },
              }}
            />
          </div>
          {more && (
            <div css={sMore}>
              {more.map((vid, id) => (
                <div css={[sPlayer, sPlayerSmall]} key={id}>
                  <ReactPlayer
                    className='react-player'
                    url={vid}
                    loop
                    width='100%'
                    height='100%'
                    playsinline
                    config={{
                      youtube: {
                        playerVars: {
                          controls: 1,
                          showinfo: 0,
                          modestbranding: 1,
                          rel: 0,
                          frameborder: 0,
                          // disablekb: 1,
                        },
                      },
                    }}
                  />
                </div>
              ))}
            </div>
          )}
        </>
      </Section>
    </>
  )
}

const sMore = {
  display: 'grid',
  // gridTemplateRows: 'calc(9fr/16)',
  [s.sm_down]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
  [s.md]: {
    gridColumnGap: '2rem',
    gridRowGap: '2rem',
    marginTop: '2rem',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [s.lg]: {
    marginTop: '4rem',
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
}

const sPlayer = {
  backgroundColor: colors.greyLighter,
  width: '100%',
  maxWidth: 960,
  height: 540,
  maxHeight: 540,
  borderRadius: '1.5rem',
  overflow: 'hidden',
  transform: 'translateZ(0)',
  backdropVisibility: 'hidden',

  [s.sm_down]: {
    borderRadius: '1rem',
    marginBottom: '1rem',
    height: 'calc(9 * (100vw - 4rem) / 16)',
  },
}

const sPlayerSmall = {
  borderRadius: '1rem',
  [s.md]: { height: '100%' },
  [s.lg]: {
    maxHeight: 180,
  },
}

export default Video
